import auth from "../../api/auth.js";

// initial state
const state = () => ({
  session: null,
});

// getters
const getters = {
  getUsername(state) {
    if (!state.session) return null;
    return state.session.sub;
  },
  canManageUsers(state) {
    if (!state.session) return false;
    return state.session.roles.includes("admin");
  },
  canDownloadLogs(state) {
    if (!state.session) return false;
    return state.session.roles.includes("admin");
  },
  isAuthenticated(state) {
    if (!state.session) return false;
    let timeNow = Math.floor(new Date().getTime() / 1000);
    return timeNow < state.session.exp;
  },
  getExpiry(state) {
    if (!state.session) return 0;
    return state.session.exp;
  },
};

// actions
const actions = {
  async login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth.login(payload.username, payload.password).then(
        (response) => {
          commit("setSession", response.data.session);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  async logout({ commit }) {
    commit("clearSession");
  },
};

// mutations
const mutations = {
  setSession(state, session) {
    state.session = session;
  },
  clearSession(state) {
    state.session = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
