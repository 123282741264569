<template>
  <el-menu
    id="sideNavMenu"
    :default-active="currentRouteIndex"
    :collapse="isCollapse"
  >
    <img
      v-if="isCollapse"
      class="sideMenuLogo"
      src="/img/tait-logo.svg"
      alt="Tait PTToX"
    />
    <img
      v-if="!isCollapse"
      class="sideMenuBanner"
      src="/img/tait-pttox-gray.svg"
      alt="Tait PTToX Administration"
    />
    <el-sub-menu index="1" test-id="monitoring_dropdown">
      <template #title>
        <el-icon><MonitoringIcon /></el-icon>
        <span class="baseMenuItem">{{ strings.monitoring_category }}</span>
      </template>
      <el-menu-item
        index="1-1"
        test-id="status_button"
        @click="navigateTo('/monitoring/status')"
        >{{ strings.system_status_page_title }}</el-menu-item
      >
      <el-menu-item
        v-if="selfHosted"
        index="1-2"
        test-id="records_button"
        @click="navigateTo('/monitoring/records')"
        >{{ strings.records_page_title }}</el-menu-item
      >
      <el-menu-item
        v-if="selfHosted && canDownloadLogs"
        index="1-3"
        test-id="logs_button"
        @click="navigateTo('/monitoring/logs')"
        >{{ strings.logs_page_title }}</el-menu-item
      >
      <el-menu-item
        index="1-4"
        test-id="devices_button"
        @click="navigateTo('/monitoring/devices')"
        >{{ strings.devices_page_title }}</el-menu-item
      >
    </el-sub-menu>
    <el-sub-menu index="2" test-id="telephony_dropdown">
      <template #title>
        <el-icon><PhoneIcon /></el-icon>
        <span class="baseMenuItem">Telephony</span>
      </template>
      <el-menu-item
        index="2-1"
        test-id="gateway_setup_button"
        @click="navigateTo('/telephony/gateway-setup')"
        >{{ strings.gateway_setup_page_title }}</el-menu-item
      >
      <el-menu-item
        index="2-2"
        test-id="ddi_button"
        @click="navigateTo('/telephony/direct-dialling')"
        >{{ strings.direct_dialling_page_title }}</el-menu-item
      >
      <el-menu-item
        index="2-3"
        test-id="inbound_button"
        @click="navigateTo('/telephony/inbound')"
        >{{ strings.inbound_page_title }}</el-menu-item
      >
      <el-menu-item
        index="2-4"
        test-id="outbound_button"
        @click="navigateTo('/telephony/outbound')"
        >{{ strings.outbound_page_title }}</el-menu-item
      >
      <el-menu-item
        index="2-5"
        test-id="pabx_button"
        @click="navigateTo('/telephony/pabx')"
        >{{ strings.pabx_page_title }}</el-menu-item
      >
    </el-sub-menu>
    <el-menu-item
      index="3"
      test-id="diversion_button"
      @click="navigateTo('/diversion')"
    >
      <el-icon><DiversionIcon /></el-icon>
      <span class="baseMenuItem">{{ strings.diversion_page_title }}</span>
    </el-menu-item>
    <el-menu-item
      index="4"
      test-id="call_options_button"
      @click="navigateTo('/call-options')"
    >
      <el-icon><CallOptionsIcon /></el-icon>
      <span class="baseMenuItem">{{ strings.call_options_page_title }}</span>
    </el-menu-item>
    <el-sub-menu
      v-if="canManageUsers"
      index="5"
      test-id="administration_dropdown"
    >
      <template #title>
        <el-icon><SettingsIcon /></el-icon>
        <span class="baseMenuItem">{{ strings.administration_category }}</span>
      </template>
      <el-menu-item
        index="5-1"
        test-id="manage_users_button"
        @click="navigateTo('/administration/manage-users')"
        >{{ strings.manage_users_page_title }}</el-menu-item
      >
      <el-menu-item
        v-if="selfHosted"
        index="5-2"
        test-id="backup_button"
        @click="navigateTo('/administration/backups')"
        >{{ strings.backups_page_title }}</el-menu-item
      >
      <el-menu-item
        v-if="selfHosted"
        index="5-3"
        test-id="client_cas_button"
        @click="navigateTo('/administration/client-cas')"
        >{{ strings.client_cas_page_title }}</el-menu-item
      >
      <el-menu-item
        v-if="selfHosted"
        index="5-4"
        test-id="core_configuration_button"
        @click="navigateTo('/administration/server-configuration')"
        >{{ strings.server_configuration_page_title }}</el-menu-item
      >
    </el-sub-menu>
    <el-menu-item index="6" test-id="logout_button" @click="logout">
      <el-icon><LogoutIcon /></el-icon>
      <span class="baseMenuItem">{{ strings.logout_page_title }}</span>
    </el-menu-item>
    <el-divider />
    <LegalFooter v-if="!isCollapse" />
    <el-button
      id="collapseButton"
      :class="isCollapse ? 'rotateIcon' : ''"
      type="primary"
      color="#eeeeee"
      circle
      @click="isCollapse = !isCollapse"
      ><el-icon><CollapseDrawerIcon /></el-icon
    ></el-button>
  </el-menu>
</template>

<script>
import PhoneIcon from "@/custom-icons/PhoneIcon.vue";
import LogoutIcon from "@/custom-icons/LogoutIcon.vue";
import SettingsIcon from "@/custom-icons/SettingsIcon.vue";
import MonitoringIcon from "@/custom-icons/MonitoringIcon.vue";
import DiversionIcon from "@/custom-icons/DiversionIcon.vue";
import CallOptionsIcon from "@/custom-icons/CallOptionsIcon.vue";
import CollapseDrawerIcon from "@/custom-icons/CollapseDrawerIcon.vue";
import serviceWorkerHelper from "@/utils/serviceWorkerHelper.js";
import strings from "@/resources/strings.js";
import store from "@/store/index.js";
import router from "@/router/index.js";
import LegalFooter from "@/components/LegalFooter.vue";
export default {
  name: "SideMenu",
  components: {
    LegalFooter,
    PhoneIcon,
    LogoutIcon,
    SettingsIcon,
    MonitoringIcon,
    DiversionIcon,
    CallOptionsIcon,
    CollapseDrawerIcon,
  },
  data() {
    return {
      selfHosted: false,
      isCollapse: false,
      routeIndexMap: {
        SystemStatusView: "1-1",
        RecordsView: "1-2",
        LogsView: "1-3",
        DevicesView: "1-4",
        GatewaySetupView: "2-1",
        DirectDiallingView: "2-2",
        InboundView: "2-3",
        OutboundView: "2-4",
        PabxView: "2-5",
        DiversionView: "3",
        CallOptionsView: "4",
        ManageUsersView: "5-1",
        BackupView: "5-2",
        ClientCAsView: "5-3",
        CoreConfigurationView: "5-4",
      },
      strings: strings,
    };
  },
  computed: {
    currentRouteIndex() {
      if (this.$route.name in this.routeIndexMap) {
        return this.routeIndexMap[this.$route.name];
      }
      return "";
    },
    canManageUsers() {
      return store.getters["auth/canManageUsers"];
    },
    canDownloadLogs() {
      return store.getters["auth/canDownloadLogs"];
    },
  },
  created() {
    this.isCollapse = window.innerWidth < 650;

    store
      .dispatch("host/isSelfHosted")
      .then((response) => {
        this.selfHosted = response;
      })
      .catch(() => {});
  },
  methods: {
    navigateTo: (path) => {
      router.push(path);
    },
    logout: () => {
      store.dispatch("auth/logout").then(() => {
        serviceWorkerHelper.resetWorker();
        router.push({ name: "LoginView" });
      });
    },
  },
};
</script>
